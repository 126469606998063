@import "variables";
@import "animation";

.boardRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  .cell {
    color: red;
  }
}

html,
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  color: #222;
  background-color: #f5be18;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 200;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: row;
  flex-flow: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $screen-xs) {
    flex-direction: column;
  }
}

.grid-container {
  float: left;
  border-radius: 3px;
  border: 3px solid rgb(18, 121, 55);
  -webkit-box-shadow: 0px 0px 14px -2px #d1d1d1;
  -moz-box-shadow: 0px 0px 14px -2px #d1d1d1;
  box-shadow: 0px 0px 14px -2px #d1d1d1;
}

#showgrid {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.row {
  width: 100%;
  height: 33.3%;
  display: flex;
}

.column {
  float: left;
  height: 100%;
  width: 33.3%;
  &-dashed {
    border: 1px dashed #e6e6e6;
  }
}

.grid-container-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  z-index: 2;

  @media screen and (max-width: $screen-sm) {
    margin-left: 0px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.itemContainer {
  width: 32.7%;
  margin-right: 1px;
}

.itemPlacedContainer {
  width: 100%;
  height: 100%;
}

.itemImage {
  width: 100%;
  height: 100%;
}

.number-wrapper {
  position: absolute;
  z-index: 100;
}

.box-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.box {
  border-radius: 3px;
  float: left;
  &:hover {
    z-index: 2;
    cursor: "grab";
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }
}

.smile_happy {
  margin-left: 40%;
  margin-bottom: 30px;
  background: url("../images/smile_ok.png") left center/100% 100% no-repeat !important;
  height: 200px;
  width: 200px;
}

.smile_unhappy {
  margin-left: 40%;
  margin-bottom: 30px;
  background: url("../images/smile_no.png") left center/100% 100% no-repeat !important;
  height: 200px;
  width: 200px;
}

.modal {
  z-index: 11;
  width: 300px;
  position: fixed;
}

.grid-box {
  border-radius: 3px;
  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}

.Overlay {
  background-color: rgba(25, 25, 112, 0.3);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.button {
  background-color: rgb(66, 150, 201);
  color: white;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  z-index: 12;

  &:hover {
    background-color: white;
    color: black;
  }
}

.itemDescription {
  text-align: center;
  font-weight: bold;
  font-family: Georgia, "Times New Roman", Times, serif;
}

html,
body {
  color: #333;
  font-size: 16px;
  line-height: 20px;
}
body {
  margin: 20px;
}

h1 {
  line-height: 1.2;
  margin-bottom: 35px;
  text-align: center;
}

.balloon {
  background: #f3f3f3;
  display: block;
  text-align: center;
  color: black;
  position: relative;
  width: 100%;
}

.triangle:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;

  border-left: 10px solid transparent;
  border-right: 10px solid #f3f3f3;
  border-top: 10px solid #f3f3f3;
  border-bottom: 10px solid transparent;
  left: 48%;
  top: -19px;
  transform: rotate(180deg);
}

.feedbackContainer {
  display: flex;
  position: fixed;
  flex-direction: column;
  bottom: 0;
  width: 100%;

  .feedbackMessageText {
    font-size: 18;
  }

  .feedbackMessageTextResult {
    font-weight: bold;
  }

  .feedbackButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 30px;

    .linkButton {
      background: transparent;
      border: none;
      color: rgb(32, 137, 220);
      font-size: 15px;
      font-weight: bold;
    }
  }

  .infoText {
    font-style: italic;
    color: rgb(3, 102, 214);
    padding: 8;
  }
}

.gameCompletedContainer {
  margin-bottom: 50px;
}

.feedbackButton {
  width: 100px;
  height: 50px;
  font-size: 16px;
  color: white;
  border-color: rgb(32, 137, 220);
  background-color: rgb(32, 137, 220);
  &:hover {
    background-color: rgb(3, 102, 214);
    font-weight: bold;
  }
}

.title {
  color: rgb(32, 137, 220);
}

.game-type-buttons {
  text-align: center;
  .gameButton {
    width: 40%;
    height: 20%;
    padding: 0;
    margin: 10px;
    .imageButton {
      width: 100%;
      height: 100%;
    }
  }
}
.footer {
  text-align: center;
}
.changeGameButton {
  margin: auto;
  margin-top: 20px;
  width: 30%;
  border-radius: 90px;
  border-width: 0;
  background-color: rgb(32, 137, 220);
  color: white;
  font-size: 15px;
  height: 40px;
}

.changeGameButton:hover {
  border-radius: 90px;
  border-width: 0;
  background-color: rgb(3, 102, 214);
}

.changeGameButton:active {
  border-radius: 90px;
  border-width: 0;
  background-color: rgb(3, 102, 214);
}
.changeGameText {
  padding-top: 10px;
}

.gameTitle {
  width: 30%;
  margin-bottom: 10px;
}