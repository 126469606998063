/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */

 @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);
              
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(1.2);
              transform: scale(1.2);      
    }
  }
  